import React from "react";
import { Link } from "react-router-dom";

// import { NumericFormat } from "react-number-format";
import styled from "styled-components";

const PriceBox = (props) => {

  const { home, choice } = props;
  const selected = home.selected;

  // 레거시
  // const Price = (props) => {
  //   return (
  //     <div>
  //       {props.count !== "0" ? (
  //         <>
  //           <div>
  //             <div className="priceRow">
  //               <span className="Year">월</span>
  //               <span className="Price">
  //                 ₩
  //                 <NumericFormat
  //                   value={props.monthPrice}
  //                   displayType={"text"}
  //                   thousandSeparator={true}
  //                 />
  //               </span>
  //             </div>
  //             <div className="priceRow">
  //               <span className="Year"> 년</span>
  //               <span className="Price">
  //                 ₩
  //                 <NumericFormat
  //                   value={props.price}
  //                   displayType={"text"}
  //                   thousandSeparator={true}
  //                 />
  //               </span>
  //             </div>
  //           </div>
  //           <span>{props.count}인 사용(부가세 포함)</span>
  //         </>
  //       ) : (
  //         <div>
  //           <span className="callus">
  //             02-598-0050 <br />
  //             (별도문의)
  //           </span>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  // 레거시
  // const BuyButton = (props) => {
  //   let path = "/apply/" + props.product;

  //   return props.count !== "0" ? (
  //     <Link
  //       to={{
  //         pathname: path,
  //         query: { type: selected, count: props.count },
  //       }}
  //     >
  //       <Btn color={color}>구매하기</Btn>
  //     </Link>
  //   ) : (
  //     <span>상담전용상품</span>
  //   );
  // };

  // 상세 상품 버튼
  const SelectButton = (props) => {
    /**
     * smartPlus는 php를 통해 내용을 접수 받음
     */
    let path =
      props.product === "smartPlus"
        ? "/customer.php"
        : "/apply/" + props.product;

    return props.product !== "smartPlus" ? (
      <Link
        to={{
          pathname: path,
          query: { type: selected, count: props.count },
        }}
      >
        <img src={`/assets/img/box_${props.product}.png`} alt="" />
      </Link>
    ) : (
      <a href={path} target="_self">
        <img src={`/assets/img/box_${props.product}.png`} alt="" />
      </a>
    );
  };

  // wrapper
  const PBox = Object.keys(choice).map((key, index) => (
    <Box key={index}>
      <SelectButton product={key} />
    </Box>
  ));

  //레거시
  // const ChoiceList = Object.keys(choice).map((key, index) => (
  //   <Box key={index}>
  //     <div className="title">{choice[key].title}</div>
  //     <div className="price">
  //       <Price {...choice[key]} />
  //     </div>
  //     <div className="desc">{choice[key].desc}</div>
  //     {selected === "solo" || selected === "smart" ? (
  //       <div className="etc">(임베디드, 웹폰트 제외)</div>
  //     ) : (
  //       ""
  //     )}
  //     <div className="buyBtn">
  //       <BuyButton product={key} />
  //     </div>
  //   </Box>
  // ));

  return <Boxes>{PBox}</Boxes>;
};

const Boxes = styled.div`
  display: flex;
  justify-content: ;
  background-color: #fff;
  // padding-bottom: 90px;
  position: relative;
  gap: 42px;
`;
const Box = styled.div`
  // background: #fff;
  // color: #666;
  height: 337px;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  // border-radius: 5px;
  // border: 1px solid #dfddde;
  // box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  // &:hover {
  //   background: #ececec;
  // }

  .title {
    font-family: HY고딕M, sans-serif;
    height: 60px;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .price {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    & {
      font-family: HY고딕B, sans-serif;
      font-size: large;
    }
    & span {
      font-size: large;
    }

    & .priceRow {
      display: flex;
      width: 140px;

      & .Year {
        font-family: HY고딕M, sans-serif;
        font-size: large;
        width: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-items: center;
      }

      & .Price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 140px;
        & span {
          font-size: large;
        }
      }
    }

    & span {
      font-size: 12px;
    }
    & .callus {
      font-size: 18px;
    }
  }

  .desc {
    height: 90px;
    font-family: HY고딕M, sans-serif;
    font-size: 16px;
    /*background-color:yellow;*/
    display: flex;
    justify-content: center;
    align-items: flex-end;
    white-space: pre-wrap;
  }

  .etc {
    font-family: HY고딕M, sans-serif;
    font-size: 10px;
    justify-content: center;
    color: #666;
  }

  .buyBtn {
    height: 114px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: HY고딕B, sans-serif;
      font-size: 21px;
    }
  }
`;



export default PriceBox;
