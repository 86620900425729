/**
 * Created by jonghwan on 2021-03-16.
 */
import React from "react";

import styled from "styled-components";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { withCookies } from "react-cookie";

// import { Tabs, TabLink, TabContent } from "react-tabs-redux";

const Email = (props) => {
  const { button, cookies, params, navigate } = props;

  const m_code = cookies.get("m_code");


  // 타입선택 , 상품상세
  // let priceType = params.type;
  // let productType = "";

  // console.log(params);

  //상품 1뎁스
  // productType = priceType === "basic" ? "basic" : "smart";

  // let selProduct = { name: productType, type: priceType };

  // 전체 선택
  const selectAll = (setFieldValue, checked) => {
    if (checked) {
      setFieldValue("isAccepted1", true);
      setFieldValue("isAccepted2", true);
      setFieldValue("isAccepted3", true);
    } else {
      setFieldValue("isAccepted1", false);
      setFieldValue("isAccepted2", false);
      setFieldValue("isAccepted3", false);
    }
  };

  const EmailSchema = yup.object({
    //email: yup.string().email("잘못된 이메일 형식입니다.").required("필수정보"),
    isAccepted1: yup.boolean().oneOf([true], "선택을 해주세요"),
    isAccepted2: yup.boolean().oneOf([true], "선택을 해주세요"),
    isAccepted3: yup.boolean().oneOf([true], "선택을 해주세요"),
  });

  button.text = "주문하기";

  return (
    <Wrapper>
      <div className="title">
        <h1> 약관동의 및 서비스 이용약관 동의 </h1>
      </div>

      <Formik
        initialValues={{
          isAccepted1: false,
          isAccepted2: false,
          isAccepted3: false,
        }}
        validationSchema={EmailSchema}
        onSubmit={(values) => {
          // console.log("2. #################################");
          // console.log("@Customer Submit");

          // console.log("Check  Cookies");


          console.log(m_code)

          // 로그인 되어 있으면 결제하기
          if (m_code) {
            //props.history.push("/apply/payment", query);

            navigate("/payment", { replace: true });
          } else {
            // 로그인 하기
            window.location.replace("/login.php?uri=/apply/" + params.option);
          }
        }}
      >
        {({ values, errors, setFieldValue }) => (
          <Form id="frm">
            <div className="desc">
              아래의 폰트바다 이용약관과 개인정보 이용 방침 및 <br /> 이메일
              수신에 모두 동의 하여야 서비스가 제공됩니다.
            </div>

            <CheckboxGroup>
              <div className="CheckboxRow">
                <Field
                  type="checkbox"
                  name="allCheck"
                  onClick={(e) => selectAll(setFieldValue, e.target.checked)}
                />

                <label className="m20">전체 동의</label>
              </div>

              <div className="CheckboxRow">
                <Field type="checkbox" name="isAccepted1" />
                <label className="m20">
                  {" "}
                  <a href="/service.php" target="_new">
                    서비스 이용약관
                  </a>{" "}
                  동의 <span className="Required">(필수)</span>
                </label>

                <div className="warn">
                  <ErrorMessage name="isAccepted1" />
                </div>
              </div>
              <div className="CheckboxRow">
                <Field type="checkbox" name="isAccepted2" />
                <label className="m20">
                  {" "}
                  <a href="/privacy.php" target="_new">
                    개인정보 이용약관
                  </a>{" "}
                  동의 <span className="Required">(필수)</span>
                </label>
                <div className="warn">
                  <ErrorMessage name="isAccepted2" />
                </div>
              </div>
              <div className="CheckboxRow">
                <Field type="checkbox" name="isAccepted3" />
                <label>
                  {" "}
                  이메일 수신 동의 <span className="Required">(필수)</span>
                </label>
                <div className="warn">
                  <ErrorMessage name="isAccepted3" />
                </div>
              </div>
            </CheckboxGroup>

            <FlexCenter>
              <Buttons type="submit">계속 하기 </Buttons>
            </FlexCenter>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
    & + & {
        margin-top: 1rem;
    }
    font-family: HY고딕M, sans-serif;
    font-size: 18px;
    width: 450px;

    a {
        outline: none;
        text-decoration: none;
        padding: 2px 1px 0;
    }

    a:link {
        color: blue;
    }

    a:visited {
        color: blue;
    }

    a:focus,
    a:hover {
        border-bottom: 1px solid;
    }

    a:active {
        color: red;
    }


    h1 {                
        font-family: HY고딕M, sans-serif;
        font-size: 22px;        
        align-items: center;
        word-spacing: -1px;
        padding: 36px 0px 36px 0px;
    }
 

    .title {        
        display:flex;
        align-items: center;
        color:#3f3f40;
    }

    .desc {        
        font-family: HY고딕M, sans-serif;
        width:100%; 
        font-size: 18px;
    }

    .warn {
        font-size: 10px;
        color: red;
    }

    .Required {
      font-size: 10px;
      color:#666;
    }

 

    input[type="checkbox"] {
        width: 14px !important;
        height: 14px !important;
        margin: 5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        outline: 1px solid gray;
        box-shadow: none;
        font-size: 0.8em;
        text-align: center;
        line-height: 1em;
        background: #fff;
    }

    input[type="checkbox"]:checked:after {
        content: "✔";
        color: gray;
    }

    .hr {
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;

        hr {
            display: block;
        }

        span {
            position: absolute;
            top: -11px;
            background: #fff;
            padding: 0 20px;
            font-weight: light;
            font-size: 12px;
            color: #666;
        }
    }

    #frm .tabs {
        width: 450px;
        display: inline-block;
        margin-right: 30px;
        vertical-align: top;
    }

    #frm .tabs-1 .tab-links {
        margin: 0;
        padding: 0;
        border: 1px solid #ccc;
        border-bottom: 1px solid #868686;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 35px;
        position: relative;
        top: 5px;
    }

    #frm .tabs-1 .tab-link {
        padding: 0 15px;
        cursor: pointer;
        border: 1px solid transparent;
        background: transparent;
        display: inline-block;
        position: relative;
        height: 40px;
        line-height: 41px;
        top: -4px;
        left: -1px;
        outline: none;
    }

    #frm .tabs-1 .tab-link:hover,
    #frm .tabs-1 .tab-link:focus {
        color: #666464;
    }

    #frm .tabs-1 .tab-link-active,
    #frm .tabs-1 .tab-link-active:hover,
    #frm .tabs-1 .tab-link-active:focus {
        color: black;
        font-weight: bold;
        border: 1px solid #868686;
        border-bottom: 1px solid white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: white;
    }

    #frm .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #frm .tabs-1 .content {
        padding: 15px;
        border: 1px solid #868686;
        border-top: 1px solid transparent;
        height: 250px;
    }

    #frm .tabs-1 .tabcontent: {
    }

    #frm .react-tabs {
        width:450px;
        height:190px;
    }


    .box {
        width: 100%;        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top:30px;
    }
    }
`;
export const CheckboxGroup = styled.div`
  display: block;
  margin-top: 15px;

  .selectAll {
    padding-bottom: 10px;
  }

  .CheckboxRow {
    display: flex;
    align-items: center;
    & span {
      font-size: 10px;
      color: red;
    }
  }

  .m18 {
    font-family: HY고딕M, sans-serif;
    font-size: 18px;
  }

  .m20 {
    font-family: HY고딕M, sans-serif;
    font-size: 18px;
    line-height: 180%;
  }
`;
export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 146px;
`;
export const KakaoLogin = styled.div`
  padding: 1px;
  width: 100%;

  .kakao {
    --button-bg-color: #ffd800;
    width: 100%;
  }
`;
export const Buttons = styled.button`
  --button-hover-bg-color: #00bcd7;
  --button-bg-color: #08cdd4;
  --button-color: #fff;

  font-family: HY고딕M, sans-serif;
  font-size: 20px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 230px;

  background: var(--button-bg-color);
  color: var(--button-color);

  margin: 0;
  padding: 0.5rem 1rem;

  font-weight: 400;
  text-align: center;
  text-decoration: none;

  display: inline-flex;
  outline: none;

  border-radius: 4px;

  cursor: pointer;

  border: none;
  border-radius: 4px;

  display: inline-block;

  cursor: pointer;

  transition: 0.5s;
`;
export default withCookies(Email);
