/**
 * Created by Jeongbin on 2017-06-23.
 */
import { HOME } from "../actions/ActionTypes";

const initialState = {
  home: {
    selected: "smart",
    target: "상업",
    color: "#e81f61",
    options: {
      webtoon: {
        webtoon1: {
          title: "WEBTOON",
          price: "110000",
          monthPrice: "10000",
          count: "1",
          desc: "웹툰전용 폰트제공 \n 웹툰제작 및 웹툰홍보",
        },
      },
      cad: {
        cad1: {
          title: "CAD",
          price: "88000",
          monthPrice: "8000",
          count: "1",
          desc: "CAD 전용 폰트제공 \n 도면 및 문서 작성",
        },
      },
      solo: {
        solo1: {
          title: "SOLO",
          price: "99000",
          monthPrice: "9000",
          count: "1",
          desc: "개인사용 \n 모든 라이선스 제공",
        },
        soloEdu: {
          title: "SOLO 학생용",
          price: "55000",
          monthPrice: "5000",
          count: "1",
          desc: "초·중·고 대학생용\n 모든 라이선스 제공",
        },
      },
      smart: {
        smart1: {
          title: "Smart one",
          price: "440000",
          monthPrice: "40000",
          count: "1",
          desc: "100인 미만 기업/기관 \n 모든 라이선스 제공",
        },
        smart25: {
          title: "Smart 25",
          price: "990000",
          monthPrice: "90000",
          count: "25",
          desc: "100인 미만 기업/기관 \n 모든 라이선스 제공",
        },
        smart50: {
          title: "Smart 50",
          price: "1430000",
          monthPrice: "120000",
          count: "50",
          desc: "100인 미만 기업/기관 \n 모든 라이선스 제공",
        },
        smartPlus: {
          title: "Smart Plus",
          price: "상담전용상품",
          monthPrice: "8000",
          count: "0",
          desc: "100인 이상 대기업/기관 \n 폰트 컨설팅 지원",
        },
      },
    },
  },
};

const homeReducer = (home = initialState.home, action) => {
  // console.log(action)

  switch (action.home) {
    case HOME.RESET:
      return home;
    case HOME.CHANGE:
      home.selected = "smart";

      Object.keys(home.options).forEach((key) => {
        // 선택한 상품과 같으면
        if (key === action.params) {
          home.selected = action.params;

          // target
          home.target = action.params === "solo" ? "비상업" : "상업";

          //  color
          //home.color = action.params === "webtoon" ? "#a7c400" : action.params === "cad" ? "#00bcd7" : action.params === "solo" ? "#FCC728" : action.params === "smart" ? "#e81f61" : "";
          home.color = "#e81f61";
        }
      });

      return { ...home };
    default:
      return home;
  }
};

export default homeReducer;
