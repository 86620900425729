/**
 * Created by jonghwan on 2021-03-16.
 */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Breadcrumb from "./Breadcrumb.js";
import { isMacOs } from "react-device-detect";

import guideImage from "../../assets/img/guide/guide-download.png";
import guideImageMac from "../../assets/img/guide/guide-download-mac.png";

const Download = (props) => {
  // const { types, query, location, company, user, payment, Download, button } =
  //   props;

  let title, link, imgGuide;

  if (isMacOs) {
    title = "맥용 매니저 설치";
    link = "https://www.fontbada.co.kr/download/Fontbada3_installer.dmg";
    imgGuide = guideImageMac;
  } else {
    title = "윈도우용 매니저 설치";
    link = "https://www.fontbada.co.kr/download/FontBada3_Manager_Setup.zip";
    imgGuide = guideImage;
  }

  console.log("browser");

  return (
    <Wrapper>
      <Container guide={imgGuide}>
        <div className="breadcrumb">
          <Breadcrumb step="download" />
        </div>
        <div className="title">
          <div>
            <a href={link}>
              <span>{title}</span>
            </a>
          </div>
        </div>
        <span className="memo">
          *사용하는 OS에 따라 화면이 다를수 있습니다.
        </span>
        <img src={imgGuide} width="1019px" alt="guide" />
        <div className="button">
          <Link to="/install">
            <Button>폰트 사용 보기</Button>
          </Link>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center !important;
`;
const Container = styled.div`
  display: flex;
  width: 1022px;
  flex-direction: column;
  justify-content: center;

  .breadcrumb {
    margin-top: 35px;
  }
  .title {
    display: inline-flex;
    margin-top: 15px;
    height: 60px;
    margin-left: 10px;

    span {
      color: #0ba7c0;
      text-decoration: underline;
      text-underline-position: under;
      width: 300px;
      display: inline;
      font-family: HY고딕L, sans-serif;
      font-size: 32px;
    }

    span::after {
      display: block;
      content: url("/img/guide/icon_download.png");
      display: inline;
      vertical-align: middle;
      margin-left: 5px;
    }

    .icons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .guide-image {
    background: url(${(props) => props.guide}) no-repeat center top;
    height: 1473px;
  }
  .button {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
  }
  .memo {
    font-size: 14px;
    margin-left: 10px;
    padding-bottom: 10px;
  }
`;
const Button = styled.div`
  --button-bg-color: #08cdd4;
  --button-color: #ffffff;
  --button-hover-bg-color: #025ce2;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 230px;
  background: var(--button-bg-color);
  color: var(--button-color);
  margin: 0;
  padding: 0.5rem 1rem;
  font-weight: 400;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: none;
  border-radius: 25px;
  display: inline-block;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-top: 0.6rem;
  padding-bottom: 0.5rem;
`;
// const Icons = styled.img.attrs((props) => ({
//   src: props.src,
// }))`
//   height: 40px;
//   width: 40px;
//   cursor: pointer;
//   margin-left: 5px;
// `;

export default Download;
