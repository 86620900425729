/**
 * Created by Jeongbin on 2017-05-17.
 */
import * as type from "./ActionTypes";

export function homeAction(home) {
  return {
    type: type.HOME,
    home,
  };
}

export function homeChoiceAction(choice, params) {
  return {
    type: type.HOME_CHOICE,
    choice,
    params,
  };
}

/*
export function applyAction(apply) {
  return {type: APPLY, apply};
}
*/

export function applyUserAction(user, params) {
  return {
    type: type.APPLY_USER,
    user,
    params,
  };
}

export function applyStudentAction(student, params) {
  return {
    type: type.APPLY_STUDENT,
    student,
    params,
  };
}

export function applyCompleteAction(complete, params) {
  // console.log("#applyCompleteAction");
  // console.log(type.APPLY_COMPLETE);
  // console.log(complete);
  // console.log(params);

  return {
    type: type.APPLY_COMPLETE,
    complete,
    params,
  };
}

export function applyHowAction(how, params) {
  return {
    type: type.APPLY_HOW,
    how,
    params,
  };
}

export function applyStepAction(step, params) {
  return {
    type: type.APPLY_STEP,
    step,
    params,
  };
}

export function applyPaymentAction(payment, params) {
  return {
    type: type.APPLY_PAYMENT,
    payment,
    params,
  };
}

export function applyCompanyAction(company, params) {
  return {
    type: type.APPLY_COMPANY,
    company,
    params,
  };
}

export function applyReceiptAction(receipt, params) {
  return {
    type: type.APPLY_RECEIPT,
    receipt,
    params,
  };
}

export function applyCardReceiptAction(cardReceipt, params) {
  return {
    type: type.APPLY_CARD_RECEIPT,
    cardReceipt,
    params,
  };
}

export function applyUsageAction(usage, params) {
  return {
    type: type.APPLY_USAGE,
    usage,
    params,
  };
}

export function applyLicenseAction(license, params) {
  return {
    type: type.APPLY_LICENSE,
    license,
    params,
  };
}

export function applyButtonAction(button, params) {
  if (button === "SET_BUTTON") {
    // console.log("applyButtonAction");
    // console.log(button);
    // console.log(params);

    return {
      type: type.APPLY_BUTTON,
      button,
      params,
    };
  }
}

export function applyMasterAction(master, params) {
  return {
    type: type.APPLY_MASTER,
    master,
    params,
  };
}

export function applyPromotionAction(promotion, params) {
  return {
    type: type.APPLY_PROMOTION,
    promotion,
    params,
  };
}

export function applyBillAction(bill, params) {
  return {
    type: type.APPLY_BILL,
    bill,
    params,
  };
}

export function applyCustomerAction(customer, params) {
  return {
    type: type.APPLY_CUSTOMER,
    customer,
    params,
  };
}

export function applyButtonRequest(applyType, params) {


  // console.clear()
  // console.log("********************");
  // console.log("* 1 결제방법:" + applyType);
  // console.log(params);

  //let { types, company, receipts, usage } = params;

  // console.log(receipts);
  // console.log(usage);

  return (dispatch) => {
    let req, merchant_uid, IMP;

    let {
      types,
      user,
      customer,
      how,
      company,
      cookies,
      navigate,
      usage,
      receipts,
    } = params;

    // TRIAL,PROMOTION,BANK,CARD
    applyType = applyType.toUpperCase();
    // 가격정보
    let prdOptions = types[company.product].options;

    let product_type = company.product.toUpperCase();
    //상품 선택 상세

    let userSelected = company.selected;

    //상품명 , 웹툰,smart one...
    let prd_name = prdOptions[`${userSelected}`].name;

    let prd_code = prdOptions[`${userSelected}`].code;

    // 용도 , 개인용 기업용...
    let prd_type = prdOptions[`${userSelected}`].type;

    //category
    let prd_category = prdOptions[`${userSelected}`].category;

    // 사용자 수
    let userCount = user.selected;
    // 전체가격
    let prdTotalPrice = company.price * userCount; //선택한 상품 전체 가격
    //결제수단
    let payType = how.selected;

    // 제공 라이선스 수
    //let prd_copy = prdOptions[`${userSelected}`].copy;
    let prd_copy = user.selected;


    merchant_uid = "merchant_" + new Date().getTime();

    //cookies.get("m_code")
    let customerInfo = {
      merchant_uid: merchant_uid,
      account: customer.form.account.value,
      account_type: customer.form.account_type.value,
      member_idx: cookies.cookies.m_uid,
      prd: product_type,
      prd_name: prd_name,
      prd_period: 1,
      prd_type: prd_type,
      prd_copy: prd_copy,
      prd_category: prd_category,
      prd_code: prd_code, // smart1,smart25,smart50
      prd_amount: prdTotalPrice,
      prd_intallment: company.type,
      pay_method: payType,
      buyer_email: customer.form.email.value,
      buyer_name: customer.form.company.value,
      buyer_tel: customer.form.tel.value,
      buyer_company: customer.form.company.value,
      buyer_ceo: customer.form.owner.value,
      buyer_company_no: customer.form.businessNum.value,
      buyer_category1: customer.form.field.value,
      buyer_category2: customer.form.field2.value,
      buyer_address: customer.form.address.value,
      buyer_manager: customer.form.manager.value
        ? customer.form.manager.value
        : customer.form.company.value,
      promotion: params.promotion.value,
      tax_receipt: receipts.selected,

      tax_receipt_type: usage.method.selected,
      tax_receipt_option: usage.type.selected,
      tax_receipt_code: usage.type.code,
      result_code: true,
      attach: customer.form.attach ? customer.form.attach.value : ""
    };


    // console.log(customerInfo)


    // 프로모션 및 트라이얼 처리
    if (["trial", "soloEdu"].includes(company.selected)) {

      setRequest(customerInfo);

    } else {

      //년결제
      if (["year", "unlimited"].includes(company.type)) {

        PGYearly();

        //정기 월결제
      } else {

        PGMonthly();

      }

    }

    /**
     *
     * @returns 년결제 실행
     */
    function PGYearly() {
      IMP = window.IMP;

      IMP.init("imp00020777"); //hanyang

      let pay_method = applyType === "CARD" ? "card" : "vbank";

      return IMP.request_pay(
        {
          pg: "html5_inicis", //ActiveX 결제창은 inicis를 사용
          pay_method: pay_method,
          merchant_uid: merchant_uid,
          name: "주문명: 폰트바다 클라우드 서비스",
          amount: customerInfo.prd_amount,
          prd_type: customerInfo.prd_type,
          prd_copy: customerInfo.prd_copy,
          // customer_uid: cookies.cookies.m_uid,
          buyer_email: customerInfo.buyer_email,
          buyer_name: customerInfo.buyer_name,
          buyer_tel: customerInfo.buyer_tel,
          buyer_addr: "",
          buyer_postcode: "",
        },
        (rsp) => {
          // 결제성공
          if (rsp.success) {
            req = {
              ...customerInfo,
              result_code: true,
              result_msg: rsp.status,
              error_msg: rsp.error_msg,
              merchant_uid: rsp.merchant_uid,
              imp_uid: rsp.imp_uid,
              result_amount: rsp.paid_amount,
              receipt_url: rsp.receipt_url,
              vbank_date: rsp.vbank_date,
              vbank_holder: rsp.vbank_holder,
              vbank_name: rsp.vbank_name,
              vbank_num: rsp.vbank_num,
              success: rsp.success,
            };
          } else {
            req = {
              ...customerInfo,
              result_code: false,
              result_msg: rsp.error_msg,
              error_msg: rsp.error_msg,
              merchant_uid: rsp.merchant_uid,
              imp_uid: rsp.imp_uid,
              result_amount: customerInfo.prd_amount,
              success: rsp.success,
            };
          }

          setRequest(req);


        }
      );
    }


    //정기결제
    function PGMonthly() {
      var form = document.createElement("form");
      form.setAttribute("id", "form");
      form.setAttribute("method", "Post");
      form.setAttribute("action", "/stdpay/pay/index.php");
      // encoding 필요 시
      // form.setAttribute("encType", "application/x-www-form-urlencoded");

      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "member_idx");
      hiddenField.setAttribute("value", customerInfo.member_idx);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "prd_copy");
      hiddenField.setAttribute("value", customerInfo.prd_copy);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_company");
      hiddenField.setAttribute("value", customerInfo.buyer_company);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_ceo");
      hiddenField.setAttribute("value", customerInfo.buyer_ceo);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_company_no");
      hiddenField.setAttribute("value", customerInfo.buyer_company_no);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_category1");
      hiddenField.setAttribute("value", customerInfo.buyer_category1);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_category2");
      hiddenField.setAttribute("value", customerInfo.buyer_category2);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_name");
      hiddenField.setAttribute("value", customerInfo.buyer_name);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_email");
      hiddenField.setAttribute("value", customerInfo.buyer_email);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "buyer_tel");
      hiddenField.setAttribute("value", customerInfo.buyer_tel);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "prd_amount");
      hiddenField.setAttribute("value", customerInfo.prd_amount);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "prd");
      hiddenField.setAttribute("value", customerInfo.prd);
      form.appendChild(hiddenField);

      hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "prd_category");
      hiddenField.setAttribute("value", customerInfo.prd_category);
      form.appendChild(hiddenField);

      document.body.appendChild(form);

      form.submit();

      //console.log(param);
    }

    /**
     *  결제 버튼 실행
     * @param {*} rsp
     * @returns
     */
    function setRequest(rsp) {
      // axios 실행 및 페이지 전환

      //console.clear();
      //console.log("**** 결과 처리 ****** ");

      let completeParams;
      completeParams = {
        query: params.query,
        how: params.how,
        merchant_uid,
        amount: prdTotalPrice,
        message: rsp.error_msg,
        imp_uid: rsp.imp_uid,
        success: rsp.success,
        vbank_date: rsp.vbank_date,
        vbank_holder: rsp.vbank_holder,
        vbank_name: rsp.vbank_name,
        vbank_num: rsp.vbank_num,
        result_amount: prdTotalPrice,
      };




      // 서버에 호출하여 결과값을 리듀서에 포함시킴
      if (rsp.result_code === true) {
        fetch(`${type.API_URL}orders`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(rsp),
        })
          .then((res) => res.json())
          .then((data) => {
            dispatch(
              applyCompleteAction(applyType, { ...completeParams, ...data })
            );
            navigate("/complete", { replace: true });
          });
      } else {
        dispatch(applyCompleteAction(applyType, completeParams));
        navigate("/complete", { replace: true });
      }
    }
  };
}
