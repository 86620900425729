import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import { ErrorMessage, Formik, Field, Form } from "formik";
import * as yup from "yup";
import Button from "./Button";

import classnames from "classnames";
// import { DisplayFormikState } from "./Helper";

//validation
const CustomerSoloSchema = yup.object({
  company: yup
    .string()
    .min(2, "고객명이 너무 짧습니다.")
    .max(50, "고객명이 너무 깁니다.")
    .required("필수정보"),
  tel: yup.string().required("필수정보"),
  email: yup.string().required("필수정보"),
});

const CustomerSoloStudentSchema = yup.object().shape({
  company: yup
    .string()
    .min(2, "고객명 너무 짧습니다.")
    .max(50, "고객명이 너무 깁니다.")
    .required("필수정보"),
  tel: yup.string().required("필수정보"),
  email: yup.string().required("필수정보"),
  field: yup.string().required("필수정보"),
  field2: yup.string().required("필수정보"),
  image: yup.mixed().required("학생증 사본을 첨부해주세요"),
});

//validation
const CustomerSchema = yup.object({
  company: yup
    .string()
    .min(2, "고객명이 너무 짧습니다.")
    .max(50, "고객명이 너무 깁니다.")
    .required("필수정보"),
  tel: yup.string().required("필수정보"),
  businessNum: yup
    .string()
    .min(2, "사업자번호가 잘못 되었습니다.")
    .max(13, "사업자번호가 잘못 되었습니다.")
    .required("필수정보"),
  email: yup.string().required("필수정보"),
  field: yup.string().required("필수정보"),
  field2: yup.string().required("필수정보"),
  manager: yup.string().required("필수정보"),
  owner: yup.string().required("필수정보"),
});

//form
const Customer = (props) => {
  // 이용구분 기업/기관정보 , 개인을  라디오 박스로
  // 최초값은 company

  const { customer, button, how, company } = props;
  const { onCustomer, asyncButton } = props;


  const [type, setType] = useState("student");
  const [image, setImage] = useState("");
  const [imageSrc, setImageSrc] = useState("");


  const initialValues = {
    account: customer.form.account.value,
    company: customer.form.company.value,
    tel: customer.form.tel.value,
    businessNum: customer.form.businessNum.value,
    email: customer.form.email.value,
    field: customer.form.field.value,
    field2: customer.form.field2.value,
    manager: customer.form.manager.value,
    owner: customer.form.owner.value,
    image: image,
  };

  useEffect(() => {
    const useTypeInit = company.selected === "soloEdu" ? "student" : "company";
    setType(useTypeInit);
  }, [company]);

  // 상품 종류에 따라 출력이 다름
  const personHidden = ["businessNum", "field", "field2", "manager", "owner"];
  const studentHidden = ["businessNum", "manager", "owner"];
  const companyHidden = [];

  const hiddenField =
    type === "company"
      ? companyHidden
      : type === "student"
        ? studentHidden
        : personHidden;

  // validation 체크
  const validation =
    type === "company"
      ? CustomerSchema
      : type === "student"
        ? CustomerSoloStudentSchema
        : CustomerSoloSchema;

  button.text = "결제하기";

  const encodeFileToBase64 = (values, fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImageSrc(reader.result);
        resolve();

        // 아이디카드 이미지
        onCustomer("PUT", {
          name: "data",
          value: { ...values, attach: reader.result.split(",")[1] },
        });
      };
    });
  };

  const MyForm = () => {
    return (
      <FormGroup>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            //입력된 사용자 정보
            onCustomer("PUT", {
              name: "data",
              value: values,
            });

            setSubmitting(false);
            asyncButton(how.selected, props);
          }}
        >
          {({ isSubmitting, values }) => (
            <div className="customerInfo ">
              {/* <DisplayFormikState {...customer} /> */}
              <Form>
                <Fragment>
                  <div className="row">
                    <div className="col-12 ">
                      <Field
                        className={company.product}
                        type="text"
                        name="company"
                        placeholder="고객명"
                      />

                      <ErrorMessage
                        name="company"
                        component="div"
                        className="err"
                      />
                    </div>
                  </div>

                  <div
                    className={classnames("row", {
                      hidden: hiddenField.includes("businessNum"),
                    })}
                  >
                    <div className="col-12 ">
                      <Field
                        className={company.product}
                        type="text"
                        name="businessNum"
                        placeholder="사업자번호(사업자번호가 없는 경우 111-11-11111)"
                        maxLength="12"
                      />
                      <ErrorMessage
                        name="businessNum"
                        component="div"
                        className="err"
                      />
                    </div>
                  </div>

                  <div
                    className={classnames("row", {
                      hidden: hiddenField.includes("owner"),
                    })}
                  >
                    <div className="col-12 ">
                      <Field
                        className={company.product}
                        name="owner"
                        placeholder="대표자"
                      />

                      <ErrorMessage
                        name="owner"
                        component="div"
                        className="err"
                      />
                    </div>
                  </div>

                  <div
                    className={classnames("row", {
                      hidden: hiddenField.includes("field"),
                    })}
                  >
                    <div className="col-5">
                      <Field
                        className={company.product}
                        type="text"
                        name="field"
                        maxLength={20}
                        placeholder={
                          company.selected === "soloEdu" ? "대학(소속)" : "업종"
                        }
                      />

                      <ErrorMessage
                        name="field"
                        component="div"
                        className="err"
                      />
                    </div>
                    <div className="col-5">
                      <Field
                        className={company.product}
                        type="text"
                        name="field2"
                        maxLength={20}
                        placeholder={
                          company.selected === "soloEdu" ? "전공" : "업태"
                        }
                      />
                      <ErrorMessage
                        name="field2"
                        component="div"
                        className="err"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className={
                        hiddenField.includes("manager") ? "hidden" : "col-5"
                      }
                    >
                      <Field
                        className={company.product}
                        type="text"
                        name="manager"
                        placeholder="담당자"
                      />
                      <ErrorMessage
                        name="manager"
                        component="div"
                        className="err"
                      />
                    </div>

                    <div
                      className={
                        hiddenField.includes("manager") ? "col-12" : "col-5"
                      }
                    >
                      <Field
                        className={company.product}
                        type="text"
                        name="email"
                        placeholder="이메일"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="err"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className={
                        hiddenField.includes("tel") ? "col-5" : "col-12"
                      }
                    >
                      <Field
                        className={company.product}
                        name="tel"
                        placeholder="연락처"
                        maxLength="13"
                      />
                      <ErrorMessage
                        name="tel"
                        component="div"
                        className="err"
                      />
                    </div>
                  </div>

                  {type === "student" ? (
                    <div className="row">
                      <div
                        className={
                          hiddenField.includes("image") ? "col-5" : "col-12"
                        }
                      >
                        <Field
                          className={company.product}
                          name="image"
                          id="image"
                          type="file"
                          title="학생증 사본을 첨부 해주세요"
                          value={undefined}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            encodeFileToBase64(values, e.target.files[0]);
                            setImage("ok");
                            //imageLabel.current.value = "";
                          }}
                        ></Field>
                        <label
                          className="input-file-button"
                          htmlFor="image"
                          id="image-label"
                        >
                          {" "}
                          학생증 사본 첨부
                        </label>
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="err"
                        />
                        <div id="image_container"></div>
                        <div className="preview">
                          {imageSrc && (
                            <img
                              src={imageSrc}
                              width="450px"
                              alt="preview-img"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <Button {...props} disabled={isSubmitting} />
                  </div>
                </Fragment>
              </Form>
            </div>
          )}
        </Formik>
      </FormGroup>
    );
  };

  // 이용구분
  const RadioForm = () => {
    let types =
      company.selected !== "soloEdu"
        ? {
          company: { label: "기업/기관정보", value: "company" },
          person: { label: "개인", value: "person" },
        }
        : {
          student: { label: "대학생", value: "student" },
        };

    return (
      <>
        <div className="form-control">
          {Object.keys(types).map((key) => (
            <div className="radio-group" key={key}>
              <input
                type="radio"
                name="customer"
                id={key}
                onChange={(e) => {
                  setType(key);
                }}
                value={types[key].value}
                checked={key === type ? true : false}
              />
              <label htmlFor={key}>
                <div className="radio-area">
                  <span></span>
                </div>
                {types[key].label}
              </label>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <Customers>
      <h2> 이용구분 </h2>
      <>
        <div className="form-inline receipts">
          <RadioForm />

          <MyForm />
        </div>
      </>
    </Customers>
  );
};

const Customers = styled.div`
  width: 465px;

  & h2 {
    font-family: HY고딕M, sans-serif;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: normal;
  }

  
  .form-control {
    flex-wrap: wrap;
    flex-basis: 0;
    padding:10px 0px 10px 0px;
  }

  .radio-group {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;

    label {
      font-family: HY고딕L, sans-serif;
      font-size: 14px;
      padding: 2px;

      span {
        width: 14px;
        height: 14px;

        span {
          width: 8px;
          height: 8px;
        }
      }
    }

    --color: #fefefe;
    --bg-color: #08cdd4;
    --font-color:#666;
 

    input {
      display: inline-block;
      &:checked + label {
        font-family: HY고딕L, sans-serif;
      }
    }

    input[type='radio'],
    input[type='radio']:checked {
      appearance: none;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 100%;
      margin-right: 0.1rem;
    }

    input[type='radio'] {
      background-color: var(--color);
      border: 1px solid var(--font-color);
    }
    input[type='radio']:checked {
      background-color: var(--bg-color);
    }

  .hidden {
    display:none;
  }

`;
const FormGroup = styled.div`
  margin-bottom: 74px;

  .hidden {
    display: none !important;
  }

  .customerInfo {
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0px 0px 22px 0px;

    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;

      .col-12 {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
      }

      .col-5 {
        flex-basis: 48%;
        display: flex;
        flex-wrap: wrap;
      }

      label {
        width: 100%;
        font-family: HY고딕M, sans-serif;
        font-size: 14px;
      }

      input {
        width: 100%;
        flex-basis: 100%;
        margin: 0;
        border: 1px solid #dfdedf;
        border-radius: 3px;
        font-family: HY고딕M, sans-serif;
        font-size: 16px;
        outline: none;
        height: 41px;
        box-sizing: border-box;
        padding-left: 10px;
        line-height: 41px;
      }

      .input-file-button {
        padding: 6px 25px;
        background-color: gray;
        border-radius: 4px;
        color: white;
        cursor: pointer;
      }
    }
  }

  .err {
    font-size: 10px;
    color: red;
  }
`;

export default Customer;
